import React, { useEffect} from 'react';
import { navigate } from "gatsby"

const IndexPage = () => {
    useEffect(() => {
        navigate('/template/');
    }, []);
    return (
        <></>
    )
}
export default IndexPage;